import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
// React Router for navigation
import { useNavigate } from 'react-router-dom';
// Styles
import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect } from 'react';

// MUI components
import { Button, Divider } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { formatDate, formatOnlyDate } from 'src/utils/date-utils';

import { AppDispatch } from 'src/store/store';
// Components
import FilterComponent from 'src/layouts/common/filter-search';
// Redux action
import { fetchMeltingSummary } from 'src/store/slices/meltings-slice';

import ReactTable from 'src/components/table/material-table';
import { date } from 'yup';

interface AllSummaryProps {
  onEditDetails: (date: string) => void;
}

const AllSummary: React.FC<AllSummaryProps> = ({ onEditDetails }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const router = useRouter();
  const dispatch = useDispatch<AppDispatch>();

  // States
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await dispatch(
          fetchMeltingSummary({
            pageSize,
            page: page + 1,
            startDate,
            endDate,
            searchText: searchQuery,
          })
        ).unwrap();
        // Process and set the rows
        setRows(response.records || []);
      } catch (error: any) {
        toast.error('Failed to fetch data:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [page, pageSize, searchQuery, startDate, endDate, dispatch]);

  // Handlers
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleDateChange = (startDateVal: string, endDateVal: string) => {
    setStartDate(startDateVal);
    setEndDate(endDateVal);
  };

  const handleAddDetails = (row: any) => {
    const formattedDate = formatDate(row.date);
    onEditDetails(formattedDate);
    navigate(`${row.id}`);
  };

  // Table headers
  const headers = [
    {
      accessorKey: 'date',
      header: 'Date',
      Cell: ({ row }: any) => <span>{formatOnlyDate(row?.original.date)}</span>,
    },
    { accessorKey: 'gross_weight', header: 'Gross Weight (kg)' },
    { accessorKey: 'stone_deduction', header: 'Stone Deduction (kg)' },
    { accessorKey: 'wastage_deduction', header: 'Wastage Deduction (kg)' },
    { accessorKey: 'net_weight', header: 'Net Weight (kg)' },
    {
      accessorKey: 'actions',
      header: 'Actions',
      enableSorting: false,
      Cell: ({ row }: any) => {
        const { updated_emp_id } = row.original;
        return (
          <div className={classes.actionButtonContainer}>
            {updated_emp_id ? (
              <Button
                variant="outlined"
                className={classes.editButton}
                onClick={() => handleAddDetails(row.original)}
              >
                Edit Details
              </Button>
            ) : (
              <Button
                variant="outlined"
                className={classes.addButton}
                onClick={() => handleAddDetails(row.original)}
              >
                Add Details
              </Button>
            )}
          </div>
        );
      },
    },
  ];


  return (
    <div>
      <FilterComponent
        onDateChange={handleDateChange}
        onSearch={handleSearch}
        searchResultCount={rows.length}
        disableTransactionType
        dayRange={1}
        searchPlaceholder="Search by Net/Gross wt. or Wastage/Stone deduction"
      />
      <ReactTable
        headers={headers}
        enableStickyHeader
        rows={rows}
        totalRecords={rows.length}
        page={page}
        pageSize={pageSize}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        loading={loading}
        enableSorting
      />
      <Divider />
    </div>
  );
};

const useStyles = makeStyles({
  actionButtonContainer: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editButton: {
    borderColor: '#919EAB52',
  },
  addButton: {
    borderColor: '#919EAB52',
  },
});

export default AllSummary;
