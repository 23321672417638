import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Button, Divider, Stack, Typography } from '@mui/material';
import toast from 'react-hot-toast';

// Custom Components
import FilterComponent from 'src/layouts/common/filter-search';
import TableSkeleton from 'src/components/table/table-skeleton';

// API Call
import { getGoldCollectionReport } from 'src/apis/meltings';
import { formatOnlyDate } from 'src/utils/date-utils';
import { formatNumber } from 'src/utils/purity-calculation';
import { exportToExcel, exportToPDF } from 'src/utils/export-file-utils';
import GenericTable from '../branch/tables/generic-table';

const GoldCollectionReport: React.FC = () => {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [reportData, setReportData] = useState<any[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const fetchReportData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getGoldCollectionReport(startDate, endDate, pageSize, page);
            const records = response?.records || [];
            const processedData = records.map((record: any) => {
                const collectionData = (role: string) =>
                    record.collection?.find((item: any) => item.emp_role === role) || {};

                const meltingTeam = collectionData('MELTING_TEAM');
                const accounts = collectionData('ACCOUNTS');
                return {
                    date: formatOnlyDate(record.transaction?.created_at) || 'Unknown',
                    branch: record.transaction?.branch?.name || 'Unknown',
                    customer_id: record.transaction?.customer?.id || 'Unknown',
                    customer_name: `${record.transaction?.customer?.first_name || ''} ${record.transaction?.customer?.last_name || ''}`,
                    gross_weight: formatNumber(record.branch_gross_weight || 0),
                    received_weight_gpm: formatNumber(meltingTeam.gross_weight || 0),
                    received_weight_finance: formatNumber(accounts.gross_weight || 0),
                    weight_variance: formatNumber(meltingTeam.difference || 0),
                    audit_variance: formatNumber(accounts.difference || 0),
                };
            });
            setReportData(processedData);
            setTotalRecords(response?.total_records || 0);
        } catch (error: any) {
            console.error('Error fetching report data:', error);
            toast.error(error.message || 'Error fetching report data');
        } finally {
            setLoading(false);
        }
    }, [startDate, endDate, pageSize, page]);

    useEffect(() => {
        fetchReportData();
    }, [fetchReportData]);


    // Totals Calculation
    const {
      totalGrossWeight,
      totalReceivedGrossWeightGPM,
      totalReceivedGrossWeightFinance,
      totalWeightVariance,
      totalAuditVariance,
    } = useMemo(() => {
      type FieldType = {
        gross_weight?: string;
        received_weight_finance?: string;
        received_weight_gpm?: string;
        weight_variance?: string;
        audit_variance?: string;
      };
      const parsedFields = reportData as unknown as FieldType[];
      return {
        totalGrossWeight: parsedFields.reduce(
          (sum, field) => sum + (parseFloat(field.gross_weight || '0') || 0),
          0
        ),
        totalReceivedGrossWeightGPM: parsedFields.reduce(
          (sum, field) => sum + (parseFloat(field.received_weight_gpm || '0') || 0),
          0
        ),
        totalReceivedGrossWeightFinance: parsedFields.reduce(
          (sum, field) => sum + (parseFloat(field.received_weight_finance || '0') || 0),
          0
        ),
        totalAuditVariance: parsedFields.reduce(
          (sum, field) => sum + (parseFloat(field.audit_variance || '0') || 0),
          0
        ),
        totalWeightVariance: parsedFields.reduce(
          (sum, field) => sum + (parseFloat(field.weight_variance || '0') || 0),
          0
        ),
      };
    }, [reportData]);

    const footer = useMemo(() => [
      '',
      '',
      '',
      '',
      totalGrossWeight,
      totalReceivedGrossWeightGPM,
      totalReceivedGrossWeightFinance,
      totalWeightVariance,
      totalAuditVariance,
    ], [
      totalGrossWeight,
      totalReceivedGrossWeightFinance,
      totalReceivedGrossWeightGPM,
      totalWeightVariance,
      totalAuditVariance,
    ]);


    const columns = useMemo(() => [
        { id: 'date', label: 'Date' },
        { id: 'branch', label: 'Branch' },
        { id: 'customer_id', label: 'Customer ID' },
        { id: 'customer_name', label: 'Customer Name' },
        { id: 'gross_weight', label: 'Gross Weight (Gms)' },
        { id: 'received_weight_gpm', label: 'Received Weight GPM (Gms)' },
        { id: 'received_weight_finance', label: 'Received Weight Finance (Gms)' },
        { id: 'weight_variance', label: 'Weight Variance (Gms)' },
        { id: 'audit_variance', label: 'Audit Variance (Gms)' },
    ], []);

    const Actions = () => {
        const exportPDF = () => {
            const fields = columns.map((col) => ({ header: col.label, key: col.id }));
            exportToPDF(fields, reportData, 'Gold-Collection-Report');
        };

        const exportExcel = () => {
            const tableHeader = columns.map((col) => ({
                header: col.label,
                key: col.id,
            }));
            exportToExcel(tableHeader, reportData, 'Gold-Collection-Report');
        };

        return (
            <Stack direction="row" spacing={1}>
                <Button variant="outlined" onClick={exportPDF}>
                    Export PDF
                </Button>
                <Button variant="outlined" onClick={exportExcel}>
                    Export Excel
                </Button>
            </Stack>
        );
    };

    const handleSearch = (query: string) => {
      setSearchQuery(query);
  };

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDateChange = (startDateVal: string, endDateVal: string) => {
        setStartDate(startDateVal);
        setEndDate(endDateVal);
    };

    return (
        <div>
            <FilterComponent
                onDateChange={handleDateChange}
                onSearch={handleSearch}
                searchResultCount={totalRecords}
                disableTransactionType
                dayRange={1}
                action={<Actions />}
                searchPlaceholder="Search"
            />
            {loading ? (
                <TableSkeleton />
            ) : (
                <GenericTable
                    columns={columns}
                    rows={reportData}
                    totalRecords={totalRecords}
                    page={page}
                    footer={footer}
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    loading={loading}
                />
            )}
            <Divider />
        </div>
    );
};

export default GoldCollectionReport;
